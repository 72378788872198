import React, { Component} from 'react';  

class InfoPopup extends Component {  
  render() {  
return (  
<div className='popup'>  
<div className='popup_inner'>  
<h5>{this.props.text}</h5>  
 
</div>  
</div>  
);  
}  
}  

export { InfoPopup } ;