import axios from 'axios';

const BASE_URL = '#BASE_URL#/api/v1/'

const HTTPCLIENT = axios;

const GET_ENDPOINT = BASE_URL + "ReportLookup";
const POST_ENDPOINT = BASE_URL + "Report";
class API {
    get(endpoint=GET_ENDPOINT, httpClient=HTTPCLIENT){
        return httpClient.get(endpoint)
            .then((res) => {
                return res
            })
            .catch((err) => {
                console.error(err)
            })
    }

    post(endpoint=POST_ENDPOINT, body, httpClient=HTTPCLIENT){
        
        return httpClient.post(endpoint, body)
    }
    
    put(body, httpClient=HTTPCLIENT){

        var endpoint = process.env['REACT_APP_APIGW_URL'] + process.env['REACT_APP_ENDPOINT'];

        return httpClient.post(endpoint, body)
     }
}

const APIInstance = new API();
export { APIInstance as API };