import React, { Component, Fragment} from 'react';  

class Final extends Component {  
  render() {  
    
    return (
            <Fragment>
              <div className="container h-100">
                <div className="form-group words ">
                  <h4>Thank You</h4>
                  <h5>Monitoring Questionnaire Submitted</h5>
                  <h6>Confidential</h6>
                  <div className="para line">
                   <p className="final">
                 Thank you for taking the time to fill out our Monitoring Report.
                  </p>
                  </div>
                </div>
              </div>
            </Fragment>
          );  
        }  
}  

export { Final } ;