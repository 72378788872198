import React, { Component } from "react";
import { API } from "../api";
import { Step1 } from "./step_1";
import { Step2 } from "./step_2";
import { Step3 } from "./step_3";
import { Step4 } from "./step_4";
import { updatedToggleSexState, 
         updatedToggleCommunityBackgroundState,
         updatedToggleEthnicityState,
         updatedToggleNationalityState,
         updatedToggleCountryOfBirthState, 
         serializeCountry,
         serializeNationality} from './helpers';
import { singleSelectCommunityBackgroundState, 
         multiselectDisabilitiesState, 
         singleSelectSexState,
         multiselectState,
         singleSelectEthnicityState } from './constants';
import * as Joi from "joi-full";

const DOCUMENT_ALIAS = "document";
const DEFAULT_HIRE_URL = "https://hirelocker-nri-form-app-dev.azurewebsites.net/?UniqueId=Cats-42";
const getUrlParameter = (name, location) => {
    const url = ((location) ? location : DEFAULT_HIRE_URL);
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(url);

    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
  };

class NIReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resource: null,
      currentStep: 1,
      final: false,
      showPopup: false,
      dob: "",
      dobValid: true,
      sex: "",
      communityBackground: "",
      disability: "",
      dependents: "",
      countryOfBirth: "",
      nationality: "",
      ethnicity: "",
      ethnicityOther: "",
      ethnicityMixed: "",
      disabilityOther: "",
      responsibilityOther: "",
      sexDropdownToggle: false,
      communityBackgroundDropDownToggle: false,
      disabilityDropdownToggle: false,
      responsibilitiesDropdownToggle: false,
      countryOfBirthDropdownToggle: false,
      nationalityDropdownToggle: false,
      ethnicityDropdownToggle: false,
      disabilitySelectedOption: false,
      responsibilitySelectedOption: false,
      partnershipSelectedOption: false,
      multiselect: multiselectState,
      multiselectDisabilities: multiselectDisabilitiesState,
      singleSelectCommunityBackground: singleSelectCommunityBackgroundState,
      singleSelectSex: singleSelectSexState,
      singleSelectEthnicity: singleSelectEthnicityState,
      tags: [],
      tagsDisabilities: [],
      reference: ''
    };
    this.toggleAllDropdowns = this.toggleAllDropdowns.bind(this);
    this.toggleDisabilitySelectedOption = this.toggleDisabilitySelectedOption.bind(
      this
    );
    this.toggleResponsibilitySelectedOption = this.toggleResponsibilitySelectedOption.bind(
      this
    );
    this.togglePartnershipSelectedOption = this.togglePartnershipSelectedOption.bind(
      this
    );
    this.handleDropdownSelect = this.handleDropdownSelect.bind(this);
    this.handleDobInput = this.handleDobInput.bind(this);
    this.handleEthnicityOtherInput = this.handleEthnicityOtherInput.bind(this);
    this.handleEthnicityMixedInput = this.handleEthnicityMixedInput.bind(this);
    this.handleDisabilityOtherInput = this.handleDisabilityOtherInput.bind(this);
    this.handleResponsibilityOtherInput = this.handleResponsibilityOtherInput.bind(this);
    this.handleMultiSelect = this.handleMultiSelect.bind(this);
    this.handleMultiSelectDisabilities = this.handleMultiSelectDisabilities.bind(
      this
    );
    this.toggleCommunityBackgroundCheckboxes = this.toggleCommunityBackgroundCheckboxes.bind(this);
    this.toggleSexCheckboxes = this.toggleSexCheckboxes.bind(this);
    this.toggleEthnicityCheckboxes = this.toggleEthnicityCheckboxes.bind(this);
    this.toggleNationalityCheckboxes = this.toggleNationalityCheckboxes.bind(this);
    this.toggleCountryOfBirthCheckboxes = this.toggleCountryOfBirthCheckboxes.bind(this);
    this.finish = this.finish.bind(this);
    this.togglePopup = this.togglePopup.bind(this);
    this.validateDobInput = this.validateDobInput.bind(this);

    const targetParam = getUrlParameter("UniqueId", window.location.href);
  }
  componentDidMount() {
    document.addEventListener("click", e => {
      this.toggleAllDropdowns(e, DOCUMENT_ALIAS);
      
      
      
    });
    const targetParameter = getUrlParameter("UniqueId", window.location.href);
    this.setState({
      ...this.state,
      reference: targetParameter
    })

    API.get()
      .then(res => {
        const countries = res.data.racialGroup.countryOfBirth;
        const serializeCountryOfBirth = serializeCountry(countries);
        const nationalities = res.data.racialGroup.nationality;
        const serializeClientNationality = serializeNationality(nationalities);
        
        this.setState({
          ...this.state,
          resource: res.data,
          singleSelectCountryOfBirth: serializeCountryOfBirth,
          singleSelectNationality: serializeClientNationality
        });
        window.scrollTo(0, 0);
      })
      .catch(err => {
        console.error(err);
      });

    
  }

  initialize(stateKey, stateValue) {
    this.setState({
      ...this.state,
      [stateKey]: stateValue
    });
  }
 
  

  togglePopup(){
    this.setState({
      showPopup: !this.state.showPopup
    })
  }

  toggleCommunityBackgroundCheckboxes(e, selection){
    e.preventDefault()
    const currentState = {...this.state.singleSelectCommunityBackground};
    const updatedState = updatedToggleCommunityBackgroundState(currentState, selection);
    const updatedCommunityBackground = Object.keys(this.state.singleSelectCommunityBackground)
        .filter((key) => selection === key)[0]

    this.setState({
        ...this.state,
        communityBackground: this.state.singleSelectCommunityBackground[updatedCommunityBackground].label,
        singleSelectCommunityBackground: updatedState
    })
}

toggleSexCheckboxes(e, selection){
    e.preventDefault()
    const currentState = {...this.state.singleSelectSex};
    const updatedState = updatedToggleSexState(currentState, selection);
    const updatedSex = Object.keys(this.state.singleSelectSex)
        .filter((key) => selection === key)[0]

    this.setState({
        ...this.state,
        sex: this.state.singleSelectSex[updatedSex].label,
        singleSelectSex: updatedState
    })
}

toggleEthnicityCheckboxes(e, selection){
    e.preventDefault()
    const currentState = {...this.state.singleSelectEthnicity};
    const updatedState = updatedToggleEthnicityState(currentState, selection);
    const updatedEthnicity = Object.keys(this.state.singleSelectEthnicity)
        .filter((key) => selection === key)[0]

    this.setState({
        ...this.state,
        ethnicity: this.state.singleSelectEthnicity[updatedEthnicity].label,
        singleSelectEthnicity: updatedState
    })
}

toggleCountryOfBirthCheckboxes(e, selection){
    e.preventDefault()
    const currentState = {...this.state.singleSelectCountryOfBirth};
    const updatedState = updatedToggleCountryOfBirthState(currentState, selection);
    const updatedCountryOfBirth = Object.keys(this.state.singleSelectCountryOfBirth)
        .filter((key) => selection === key)[0]

    this.setState({
        ...this.state,
        countryOfBirth: this.state.singleSelectCountryOfBirth[updatedCountryOfBirth].label,
        singleSelectCountryOfBirth: updatedState
    })
}

toggleNationalityCheckboxes(e, selection){
    e.preventDefault()
    const currentState = {...this.state.singleSelectNationality};
    const updatedState = updatedToggleNationalityState(currentState, selection);
    const updatedNationality = Object.keys(this.state.singleSelectNationality)
        .filter((key) => selection === key)[0]

    this.setState({
        ...this.state,
        nationality: this.state.singleSelectNationality[updatedNationality].label,
        singleSelectNationality: updatedState
    })
}

  toggleAllDropdowns(e, id) {
    if (e.nativeEvent) {
      e.nativeEvent.stopImmediatePropagation();
    } else {
      e.stopImmediatePropagation();
    }

    this.setState({
      ...this.state,
      sexDropdownToggle:
        id === "sex" && id !== DOCUMENT_ALIAS && !this.state.sexDropdownToggle,
      communityBackgroundDropdownToggle:
        id === "communityBackground" &&
        id !== DOCUMENT_ALIAS &&
        !this.state.communityBackgroundDropdownToggle,
      disabilityDropdownToggle:
        id === "disability" &&
        id !== DOCUMENT_ALIAS &&
        !this.state.disabilityDropdownToggle,
      responsibilitiesDropdownToggle:
        id === "responsibilities" &&
        id !== DOCUMENT_ALIAS &&
        !this.state.responsibilitiesDropdownToggle,
      countryOfBirthDropdownToggle:
        id === "countryOfBirth" &&
        id !== DOCUMENT_ALIAS &&
        !this.state.countryOfBirthDropdownToggle,
      nationalityDropdownToggle:
        id === "nationality" &&
        id !== DOCUMENT_ALIAS &&
        !this.state.nationalityDropdownToggle,
      ethnicityDropdownToggle:
        id === "ethnicity" &&
        id !== DOCUMENT_ALIAS &&
        !this.state.ethnicityDropdownToggle
    });
  }
  handleEthnicityOtherInput(e){
    this.setState({
      ...this.state,
      ethnicityOther: e.currentTarget.value
    });
  }
  handleEthnicityMixedInput(e){
    this.setState({
      ...this.state,
      ethnicityMixed: e.currentTarget.value
    });
  }
  handleDisabilityOtherInput(e){
    this.setState({
      ...this.state,
      disabilityOther: e.currentTarget.value
    });
  }
  handleResponsibilityOtherInput(e){
    this.setState({
      ...this.state,
      responsibilityOther: e.currentTarget.value
    });
  }
  handleDobInput(e) {
    this.setState({
      ...this.state,
      dob: e.currentTarget.value
    });
  }

  validateDobInput(e){
    e.preventDefault()
    const schema = Joi.object().keys({
      dob: Joi.date().format(['DD/MM/YYYY']).allow('')
    });
    const result = schema.validate({dob: this.state.dob});
    this.setState({
      ...this.state,
      dobValid: result.error ? false : true
    })
  }

  handleDropdownSelect(e, id, payload) {
    this.setState({
      ...this.state,
      [id]: payload
    });
  }

  toggleDisabilitySelectedOption() {
    this.setState({
      ...this.state,
      disabilitySelectedOption: !this.state.disabilitySelectedOption,
      disabilityOther:'',
      tagsDisabilities: [],
      multiselectDisabilities:multiselectDisabilitiesState

    });
  }

  toggleResponsibilitySelectedOption() {
    this.setState({
      ...this.state,
      responsibilitySelectedOption: !this.state.responsibilitySelectedOption,
      responsibilityOther: '',
      tags: [],
      multiselect:multiselectState
      
      
    });
  }

  togglePartnershipSelectedOption() {
    this.setState({
      ...this.state,
      partnershipSelectedOption: !this.state.partnershipSelectedOption
    });
  }

  handleMultiSelect(e, key) {
    e.preventDefault();
    e.stopPropagation();
    const newState = {
      ...this.state.multiselect,
      [key]: {
        key: this.state.multiselect[key].key,
        label: this.state.multiselect[key].label,
        selected: !this.state.multiselect[key].selected
      }
    };
    const tempMulti = Object.keys(newState).map(key => newState[key]);
    const newTagsState = tempMulti.filter(item => item.selected);
    this.setState({
      ...this.state,
      multiselect: newState,
      tags: newTagsState
    });
  }
  handleMultiSelectDisabilities(e, key) {
    e.preventDefault();
    e.stopPropagation();
    const newState = {
      ...this.state.multiselectDisabilities,
      [key]: {
        key: this.state.multiselectDisabilities[key].key,
        label: this.state.multiselectDisabilities[key].label,
        selected: !this.state.multiselectDisabilities[key].selected,
        group: "disabilities"
      }
    };

    const tempMulti = Object.keys(newState).map(key => newState[key]);
    const newTagsState = tempMulti.filter(item => item.selected);
    this.setState({
      ...this.state,
      multiselectDisabilities: newState,
      tagsDisabilities: newTagsState
    });
  }

  finish(e){
      e.preventDefault();
     
      let dis = this.state.tagsDisabilities.map((tag) => tag.label);
      let dep = this.state.tags.map((tag) => tag.label);
      
      const payload = {
        "CommunityBackground": this.state.communityBackground,
        "Sex": this.state.sex,
        "Age": this.state.dob,
        "PartnershipStatus": this.state.partnershipSelectedOption,
        "RacialGroupForm": {
            "CountryOfBirth": this.state.countryOfBirth,
            "Nationality": this.state.nationality,
            "Ethnicity": this.state.ethnicity,
            "EthnicityOther": this.state.ethnicityOther,
            "EthnicityMixed": this.state.ethnicityMixed
        },
        "Disability": {
            "Disabled": this.state.disabilitySelectedOption,
            "Disabilities": dis,
            "DisabilityOther": this.state.disabilityOther
        },
        "Responsibilities": {
            "HasDependents": this.state.responsibilitySelectedOption,
            "Dependents": dep,
            "ResponsibilityOther": this.state.responsibilityOther
        },
        "Reference": this.state.reference
    }
    console.log("payload", payload);
    API.post(undefined,payload)
       .then((res) => {
           console.log(res);
       }
       ).catch((err) => {
           console.error(err);
       })
    this.setState({
        ...this.state.final,
        final: true,
        currentStep: 4
        
      });

  }

  //////////////// STEP FORM ////////////////////
  _next = () => {
    let currentStep = this.state.currentStep;
    currentStep = currentStep >= 2 ? 3 : currentStep + 1;
    this.setState({
      ...currentStep,
      currentStep: currentStep
    });
  };

  _prev = () => {
    let currentStep = this.state.currentStep;
    currentStep = currentStep <= 1 ? 1 : currentStep - 1;
    this.setState({
      ...currentStep,
      currentStep: currentStep
    });
  };

  previousButton() {
    let currentStep = this.state.currentStep;
    if (currentStep !== 1 && currentStep !== 4) {
      return (
        <button
          className="btn btn-block btn-secondary"
          type="button"
          onClick={this._prev}
        >
          Previous
        </button>
      );
    }
    return null;
  }

  nextButton() {
    let currentStep = this.state.currentStep;
    if (currentStep < 3) {
      return (
        <div className="form-row">
          <button
            className="btn btn-block btn-secondary float-right"
            type="button"
            onClick={this._next}
          >
            Next
          </button>
        </div>
      );
    }
    return null;
  }
  finishButton() {
    let currentStep = this.state.currentStep;
    if (currentStep === 3) {
      return (
        <div className="form-row">
          <button
            onClick={this.finish}
            className="btn btn-block btn-secondary float-right ripple"
            type="button"
          >
            Finish
          </button>
        </div>
      );
    }
    return null;
  }

  render() {
    const handlers = {
      toggleAllDropdowns: this.toggleAllDropdowns,
      toggleDisabilitySelectedOption: this.toggleDisabilitySelectedOption,
      toggleResponsibilitySelectedOption: this
        .toggleResponsibilitySelectedOption,
      togglePartnershipSelectedOption: this.togglePartnershipSelectedOption,
      handleDropdownSelect: this.handleDropdownSelect,
      handleDobInput: this.handleDobInput,
      handleEthnicityOtherInput: this.handleEthnicityOtherInput,
      handleEthnicityMixedInput: this.handleEthnicityMixedInput,
      handleDisabilityOtherInput: this.handleDisabilityOtherInput,
      handleResponsibilityOtherInput: this.handleResponsibilityOtherInput,
      handleMultiSelect: this.handleMultiSelect,
      handleMultiSelectDisabilities: this.handleMultiSelectDisabilities,
      toggleCommunityBackgroundCheckboxes: this.toggleCommunityBackgroundCheckboxes,
      toggleSexCheckboxes: this.toggleSexCheckboxes,
      toggleEthnicityCheckboxes : this.toggleEthnicityCheckboxes,
      toggleCountryOfBirthCheckboxes: this.toggleCountryOfBirthCheckboxes,
      toggleNationalityCheckboxes: this.toggleNationalityCheckboxes,
      finish: this.finish,
      togglePopup: this.togglePopup,
      getUrlParameter: this.getUrlParameter,
      validateDobInput: this.validateDobInput
    };
    
    return (
      <div className="container parent">
        <div className="row">
          <div className="col-sm-12">
        

            <div className="card card_container">
              <div className="card-body others">
                {
                  this && this.state && this.state.currentStep === 1
                  ? (
                    <Step1
                    handlers={handlers}
                    resource={this.state.resource}
                    state={this.state}
                  />
                  )
                  : null
                }
                {
                  this && this.state && this.state.currentStep === 2
                  ?
                    (
                      <Step2
                      handlers={handlers}
                      resource={this.state.resource}
                      state={this.state}
                    />
                    )
                  : null
                }
                {
                  this && this.state && this.state.currentStep === 3
                  ? 
                    (
                        <Step3
                        handlers={handlers}
                        resource={this.state.resource}
                        state={this.state}
                      />
                    )
                  : null
                }
                {
                  this && this.state && this.state.currentStep === 4
                  ? 
                  (
                    <Step4 state={this.state} />
                  )
                  : null
                }


                <div className="row space">
                  <div className="col-sm-6">{this.previousButton()}</div>
                  <div className="col-sm-6 spacer">
                    {this.nextButton()}
                    {this.finishButton()}
                  </div>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export { NIReport, getUrlParameter };
