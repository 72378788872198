import React, { Component } from "react";
import { Tags } from "../../common";

const renderDropdown = (multiselect, handler) => {

  return Object.keys(multiselect).map((key, idx) => {
    return (
      <a key={idx} className="dropdown-item dropd">
        <input
          className="form-check-input"
          type="checkbox"
          checked={multiselect[key].selected}
          id={`dropdown-item${idx}`}
          onChange={e => {
            handler(e, key);
          }}
        />
        {multiselect[key].label}
      </a>
    );
  });
};

class Responsibilities extends Component {
  constructor(props){
    super(props)
    this.renderDropdown = renderDropdown.bind(this)
  }
  render() {
    const { handlers, state, id } = this.props;
    const multiselect = state.multiselect;
    const tagsHandlers = {
      handleMultiSelectDisabilities: handlers.handleMultiSelectDisabilities,
      handleMultiSelect: handlers.handleMultiSelect
    };
    return (
      <div className="row space2">
        <form className="form-horizontal col-sm-12">
          <div className="card line ">
            <div className="card-body">
              <h5 className="card-title head">
                Dependents / Caring Responsibilities
              </h5>
              <div className="form-group form-row">
                <p
                  className="control-label col-sm-12 col-md-10 col-lg-10 fsze">
                  Do you have dependents, or caring responsibilities for family
                  members or other persons?
                </p>
                <div className="form-check  col-sm-6 col-md-1 col-lg-1">
                  <input
                    className="form-check-input "
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio1"
                    value="Yes"
                    checked={
                      this.props.state.responsibilitySelectedOption === true
                    }
                    onChange={
                      this.props.handlers.toggleResponsibilitySelectedOption
                    }
                  />
                  <label className="form-check-label" htmlFor="inlineRadio1">
                    Yes
                  </label>
                </div>
                <div className="form-check col-sm-6 col-md-1 col-lg-1">
                  <input
                    className="form-check-input "
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio2"
                    value="No"
                    checked={
                      this.props.state.responsibilitySelectedOption === false
                    }
                    onChange={
                      this.props.handlers.toggleResponsibilitySelectedOption
                    }
                  />
                  <label className="form-check-label" htmlFor="inlineRadio2">
                    No
                  </label>
                </div>
              </div>
              {this.props.state.responsibilitySelectedOption === true ? (
                <div className="form-group form-row">
                  <p className="control-label col-sm-12 col-md-9 col-lg-10">
                    If you answered 'yes', are your dependents or people you
                    look after one of the following, you may select more than
                    one:
                  </p>
                  <div className="col-sm-12 col-md-3 col-lg-2">
                    <div id="depDd" className="dropdown">
                      <a
                        onClick={e => {
                          handlers.toggleAllDropdowns(e, "responsibilities");
                        }}
                        className="btn btn-md btn-secondary dropdown-toggle"
                        role="button"
                        id="dropdownMenuLink"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        {multiselect.other.selected
                          ? multiselect.other.label
                          : "Please Select"}
                      </a>

                      <div
                        className={` dropdown-menu ${
                          state[`${id}DropdownToggle`] ? "show" : ""
                        }`}
                        aria-labelledby="dropdownMenuLink"
                      >
                        {multiselect
                          ? this.renderDropdown(
                              multiselect,
                              handlers.handleMultiSelect
                            )
                          : null}
                      </div>
                    </div>
                  </div>
                  <br />
                  <br />
                  <div className="col-sm-12">
                    <Tags tags={state.tags} handlers={tagsHandlers} />
                  </div>
                  {multiselect.other.selected ? (
                    <div className="input-group other col-sm-12">
                      <div className="input-group-prepend">
                        <span className="input-group-text specify">
                          Please specify Other
                        </span>
                      </div>
                      <textarea
                      onChange={e => {
                        handlers.handleResponsibilityOtherInput(e);
                      }}
                      value={this.props.state.responsibilityOther}
                        className="form-control"
                        aria-label="With textarea"
                      />
                    </div>
                  ) : null}
                </div>
              ) : null}
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export { Responsibilities };
