import React, { Component, Fragment } from "react";
import { Disability } from "../disability";
import { Responsibilities } from "../responsibilities";
import { CountryOfBirth } from "../country_of_birth";
import { Nationality } from "../nationality";
import { Ethnicity } from "../ethnicity";

class Step3 extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    
  }
  render() {
    const { state } = this.props;

    return (
      <Fragment>
        {state.currentStep === 3 ? (
          <div className="form-group">
            <CountryOfBirth
              handlers={this.props.handlers}
              resource={this.props.state.resource}
              state={this.props.state}
              id={"countryOfBirth"}
            />
            <Nationality
              handlers={this.props.handlers}
              resource={this.props.state.resource}
              state={this.props.state}
              id={"nationality"}
            />
            <Ethnicity
              handlers={this.props.handlers}
              resource={this.props.state.resource}
              state={this.props.state}
              id={"ethnicity"}
            />
            <Responsibilities
              handlers={this.props.handlers}
              resource={this.props.state.resource}
              state={this.props.state}
              id={"responsibilities"}
            />
            <Disability
              handlers={this.props.handlers}
              resource={this.props.state.resource}
              state={this.props.state}
              id={"disability"}
            />
          </div>
        ) : null}
      </Fragment>
    );
  }
}

export { Step3 };
