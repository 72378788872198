import React, { Component } from "react";


const displayFeedBack = (state) => !state.dobValid ? { display: 'inline'} : null


// Note, onSubmit preventDefault seems to raise an error in the console
// This error is known and related to LastPass browser extension; it does
// not appear to affect the application adversely
class Age extends Component {
  render() {
    const { handlers, state } = this.props;
   
    return (
      <div className="row space">
        <form onSubmit={(e) => {e.preventDefault()}} className="form-horizontal col-md-12" noValidate>
          <div className="card line">
            <div className="card-body">
              <h5 className="card-title head">Age</h5>
              <div className="form-group form-row">
                <label
                  htmlFor="dobUk"
                  className="control-label  col-sm-12 col-md-10 col-lg-10 fsze"
                >
                  Please state your Date of Birth:
                </label>
                <input
                  id="dob"
                  name="dob"
                  defaultValue={this.props.state.dob}
                  className="form-control input-lg col-sm-12 col-sm-offset-6 col-md-2 col-lg-2"
                  type="text"
                  title="Your date of birth should be in this format dd/mm/yyyy"
                  placeholder="dd/mm/yyyy"
                  onChange={handlers.handleDobInput}
                  onBlur={handlers.validateDobInput}
                />
                {
                  state && state.dobValid
                    ? null
                    : <span className="invalid-form-input hasErr" style={displayFeedBack(state)}>Your date of birth should be in this format dd/mm/yyyy </span>
                }
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export { Age };
