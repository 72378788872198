import React from 'react';
import { NIReport } from './ni_report';
import './App.css';

function App() {
  return (
    <div className="App h-100">
      <NIReport />
    </div>
  );
}

export default App;
