import React, { Component, Fragment } from "react";
import { Age } from "../age";
import { Sex } from "../sex";
import { PartnershipStatus } from "../partnership_status";

class Step2 extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    
  }
  render() {
    const { state } = this.props;

    return (
      <Fragment>
        {state.currentStep === 2 ? (
          <div className="form-group">
            <Age
              handlers={this.props.handlers}
              resource={this.props.state.resource}
              state={this.props.state}
              id={"dob"}
            />
            <Sex
              handlers={this.props.handlers}
              resource={this.props.state.resource}
              state={this.props.state}
              id={"sex"}
            />
            <PartnershipStatus
              handlers={this.props.handlers}
              resource={this.props.state.resource}
              state={this.props.state}
              id={"partnership"}
            />
          </div>
        ) : null}
      </Fragment>
    );
  }
}

export { Step2 };
