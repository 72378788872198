import React, { Component } from "react";
import { Tags } from "../../common";

const renderDropdown = (multiselectDisabilities, handler) => {
    return Object.keys(multiselectDisabilities).map((key, idx) => {
    return (
      <a key={idx} className="dropdown-item ">
        <input
          className="form-check-input dropd"
          type="checkbox"
          checked={multiselectDisabilities[key].selected}
          id={`dropdown-item${idx}`}
          onChange={e => {
            handler(e, key);
          }}
        />
        {multiselectDisabilities[key].label}
      </a>
    );
  });
};
class Disability extends Component {

  constructor(props){
    super(props)
    this.renderDropdown = renderDropdown.bind(this)
  }

  render() {
    const {  handlers, state, id } = this.props;
    const multiselectDisabilities = state.multiselectDisabilities;

    const tagsHandlers = {
      handleMultiSelectDisabilities: handlers.handleMultiSelectDisabilities,
      handleMultiSelect: handlers.handleMultiSelect
    };
    return (
      <div className="row">
        <form className="form-horizontal col-md-12">
          <div className="card line">
            <div className="card-body">
              <h5 className="card-title head2">Disability</h5>
              <div className="form-group form-row">
                <p className="control-label col-sm-12 fsze words">
                  Under the <i>Disability Discrimination Act 1995</i> you are
                  deemed to be a disabled person if you have cancer, multiple
                  sclerosis or HIV infection. Also, you are deemed to be a
                  disabled person if you have a physical or mental impairment
                  which has a substantial and long-term adverse effect on your
                  ability to carry out normal day-to-day activities.
                </p>
                <p
                  className="control-label col-sm-12 col-md-10 col-lg-10 fsze other"
                  htmlFor="email"
                >
                  Do you consider that you are a disabled person?
                </p>
                <div className="form-check  col-sm-6 col-md-1 col-lg-1 other">
                  <input
                    className="form-check-input "
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio1"
                    value="Yes"
                    checked={this.props.state.disabilitySelectedOption === true}
                    onChange={this.props.handlers.toggleDisabilitySelectedOption}
                  />
                  <label className="form-check-label" htmlFor="inlineRadio1">
                    Yes
                  </label>
                </div>
                <div className="form-check  col-sm-6 col-md-1 col-lg-1 other">
                  <input
                    className="form-check-input "
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio2"
                    value="No"
                    checked={
                      this.props.state.disabilitySelectedOption === false
                    }
                    onChange={this.props.handlers.toggleDisabilitySelectedOption}
                  />
                  <label className="form-check-label" htmlFor="inlineRadio2">
                    No
                  </label>
                </div>
              </div>
              {this.props.state.disabilitySelectedOption === true ? (
                <div className="form-group form-row">
                  <p className="control-label col-sm-12 col-md-10 col-lg-10">
                    If you answered 'yes', please indicate the nature of your
                    impairement by selecting the appropriate from the following,
                    you may select more than one:
                  </p>
                  <div className="col-sm-12 col-md-1 col-lg-1">
                    <div id="disDd" className="dropdown">
                      <a
                        onClick={e => {
                          handlers.toggleAllDropdowns(e, "disability");
                        }}
                        className="btn btn-md btn-secondary dropdown-toggle"
                        role="button"
                        id="dropdownMenuLink"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        {multiselectDisabilities.other.selected
                          ? multiselectDisabilities.other.label
                          : "Please Select"}
                      </a>

                      <div
                        className={` dropdown-menu ${
                          state[`${id}DropdownToggle`] ? "show" : ""
                        }`}
                        aria-labelledby="dropdownMenuLink"
                      >
                        {multiselectDisabilities
                          ? this.renderDropdown(
                              multiselectDisabilities,
                              handlers.handleMultiSelectDisabilities
                            )
                          : null}
                      </div>
                    </div>
                  </div>
                  <br />
                  <br />
                  <div className="col-sm-12">
                    <Tags
                      tags={state.tagsDisabilities}
                      handlers={tagsHandlers}
                    />
                  </div>
                  {multiselectDisabilities.other.selected ? (
                    <div className="input-group other col-md-12">
                      <div className="input-group-prepend">
                        <span className="input-group-text specify">
                          Please specify Other
                        </span>
                      </div>
                      <textarea
                        onChange={e => {
                          handlers.handleDisabilityOtherInput(e);
                        }}
                        value={this.props.state.disabilityOther}
                        className="form-control"
                        aria-label="With textarea"
                      />
                    </div>
                  ) : null}
                </div>
              ) : null}
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export { Disability };
