import React, { Component } from "react";

class PartnershipStatus extends Component {
  render() {
    
    return (
      <div className="row">
        <form className="form-horizontal col-md-12">
          <div className="card line">
            <div className="card-body">
              <h5 className="card-title head">
                Martial Status/ Civil Partnership Status
              </h5>
              <div className="form-group form-row">
                <p className="control-label col-sm-12 col-md-10 col-lg-10 fsze">
                  Please indicate whether you are married or in a civil
                  partnership:
                </p>
                <div className="form-check col-sm-6 col-md-1 col-lg-1">
                  <input
                    className="form-check-input "
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio1"
                    value="Yes"
                    checked={
                      this.props.state.partnershipSelectedOption === true
                    }
                    onChange={
                      this.props.handlers.togglePartnershipSelectedOption
                    }
                  />
                  <label className="form-check-label" htmlFor="inlineRadio1">
                    Yes
                  </label>
                </div>
                <div className="form-check col-sm-6 col-md-1 col-lg-1">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio2"
                    value="No"
                    checked={
                      this.props.state.partnershipSelectedOption === false
                    }
                    onChange={
                      this.props.handlers.togglePartnershipSelectedOption
                    }
                  />
                  <label className="form-check-label" htmlFor="inlineRadio2">
                    No
                  </label>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export { PartnershipStatus };
