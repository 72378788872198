import React, { Component } from "react";

const renderDropdown = (state, handler) => {
  return Object.keys(state.singleSelectEthnicity).map((key, idx) => {
    const item = state.singleSelectEthnicity[key]
    return (
      <a
        key={idx}
        className="dropdown-item"
      >
        <input
          className="form-check-input"
          type="checkbox"
          value={item.label}
          id={idx}
          checked={item.selected}
          onChange={e => handler(e, item.id)}
        />
        {item.label}
      </a>
    );
  })
}

class Ethnicity extends Component {
  render() {
    const { resource, handlers, state, id } = this.props;
    const othergroup = this.props.state.ethnicity === "Any other ethnic group";
    const mixed = this.props.state.ethnicity === "Mixed ethnic group";

    return (
      <div className="row">
        <form className="form-horizontal col-sm-12 col-md-12 col-lg-12">
          <div className="card line">
            <div className="card-body">
              <h5 className="card-title" />

              <div className="form-group form-row">
                <p className="control-label col-sm-12 col-md-6 col-lg-6 fsze">
                  Please indicate which of the following apply to you:
                </p>
                <div className="col-sm-12 col-md-6 col-lg-6">
                  <div id="rgr" className="dropdown">
                    <a
                      onClick={e => {
                        handlers.toggleAllDropdowns(e, "ethnicity");
                      }}
                      className="btn btn-md btn-secondary dropdown-toggle"
                      role="button"
                      id="dropdownMenuLink"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {state.ethnicity ? state.ethnicity : "Please Select"}
                    </a>

                    <div
                      className={` dropdown-menu ${
                        state[`${id}DropdownToggle`] ? "show" : ""
                      }`}
                      aria-labelledby="dropdownMenuLink"
                    >
                      {resource
                        ? renderDropdown(
                          state,
                          handlers.toggleEthnicityCheckboxes,
                          id
                          )
                        : null}
                      
                    </div>
                  </div>
                </div>
                {othergroup ? (
                  <div className="input-group col-sm-12">
                    <div className="input-group-prepend">
                      <span className="input-group-text specify">
                        Please specify Other
                      </span>
                    </div>
                    <textarea
                      onChange={e => {
                        handlers.handleEthnicityOtherInput(e);
                      }}
                      value={this.props.state.ethnicityOther}
                      className="form-control"
                      aria-label="With textarea"
                    />
                  </div>
                ) : null}
                {mixed ? (
                  <div className="input-group col-sm-12">
                    <div className="input-group-prepend">
                      <span className="input-group-text specify">
                        Please specify Mixed
                      </span>
                    </div>
                    <textarea
                      onChange={e => {
                        handlers.handleEthnicityMixedInput(e);
                      }}
                      value={this.props.state.ethnicityMixed}
                      className="form-control"
                      aria-label="With textarea"
                    />
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export { Ethnicity };
