const updatedToggleSexState = (currentState, selection) => {
    const updatedState = {};
    Object.keys(currentState).map((key) => {
        if(key === selection){
            updatedState[key] = {
                id: currentState[key].id,
                label: currentState[key].label,
                selected: !currentState[key].selected
            }
        } else {
            updatedState[key] = {
                id: currentState[key].id,
                label: currentState[key].label,
                selected: false
            }
        }

    })
    return updatedState;
}

const updatedToggleCommunityBackgroundState = (currentState, selection) => {
    const updatedState = {};
    Object.keys(currentState).map((key) => {
        if(key === selection){
            updatedState[key] = {
                id: currentState[key].id,
                label: currentState[key].label,
                selected: !currentState[key].selected
            }
        } else {
            updatedState[key] = {
                id: currentState[key].id,
                label: currentState[key].label,
                selected: false
            }
        }

    })
    return updatedState;
}

const updatedToggleEthnicityState = (currentState, selection) => {
    const updatedState = {};
    Object.keys(currentState).map((key) => {
        if(key === selection){
            updatedState[key] = {
                id: currentState[key].id,
                label: currentState[key].label,
                selected: !currentState[key].selected
            }
        } else {
            updatedState[key] = {
                id: currentState[key].id,
                label: currentState[key].label,
                selected: false
            }
        }

    })
    return updatedState;
}

const updatedToggleNationalityState = (currentState, selection) => {
    const updatedState = {};
    Object.keys(currentState).map((key) => {
        if(key === selection){
            updatedState[key] = {
                id: currentState[key].id,
                label: currentState[key].label,
                selected: !currentState[key].selected
            }
        } else {
            updatedState[key] = {
                id: currentState[key].id,
                label: currentState[key].label,
                selected: false
            }
        }

    })
    return updatedState;
}

const updatedToggleCountryOfBirthState = (currentState, selection) => {
    const updatedState = {};
    Object.keys(currentState).map((key) => {
        if(key === selection){
            updatedState[key] = {
                id: currentState[key].id,
                label: currentState[key].label,
                selected: !currentState[key].selected
            }
        } else {
            updatedState[key] = {
                id: currentState[key].id,
                label: currentState[key].label,
                selected: false
            }
        }

    })
    return updatedState;
}

const serializeCountry = (countries) => {
    const serialized = {};
    countries.map((country) => {
      serialized[country.toLowerCase()] = {
        id: country.toLowerCase(),
        label: country,
        selected: false
      }
    });
    return serialized;
  };

  const serializeNationality = (nationalities) => {
    
    const serialized = {};
    nationalities.map((nationality) => {
      serialized[nationality.toLowerCase()] = {
        id: nationality.toLowerCase(),
        label: nationality,
        selected: false
      }
    });
    return serialized;
  };

export { updatedToggleSexState, 
         updatedToggleCommunityBackgroundState,
         updatedToggleEthnicityState,
         updatedToggleNationalityState,
         updatedToggleCountryOfBirthState,
         serializeCountry,
         serializeNationality }