import React, { Component, Fragment } from "react";
import { CommunityBackground } from "../community_background";
import { InfoPopup } from "../info";

class Step1 extends Component {
  componentDidMount() {
    
  }
  render() {
    const { state, handlers } = this.props;
    return (
      <Fragment>
        {this.props.state.currentStep === 1 ? (
          <Fragment>
            <div className="form-group words ">
              <h2>Confidential</h2>

              <h4>Reference Number: {state.reference}</h4>
              <h6>Monitoring Questionnaire</h6>
              <h6>Guidance Notes:</h6>
              <div className="para">
                <p>
                  We are an Equal Opportunities Employer. We aim to provide
                  equality of opportunity to all persons regardless of their
                  religious belief; political opinion; sex; race; age; or, 
                  whether they are married or are in a civil partnership; 
                  or, whether they are disabled; or whether they have 
                  undergone, are undergoing or intend to undergo gender
                  reassignment.
                </p>
                <p>
                  We do not discriminate against our job applicants or employees
                  on any of the grounds listed above. We aim to select the best
                  person for the job and all recruitment decisions will be made
                  objectively.
                </p>
                <p>
                  In this questionnaire we will ask you to provide us with some
                  personal information about yourself. We are doing this for two
                  reasons.
                </p>
                <p>
                  Firstly, we are doing this to demonstrate our commitment to
                  promoting equality of opportunity in employment. The
                  information that you provide us will assist us to measure the
                  effectiveness of our equal opportunity policies and to develop
                  affirmative or positive action policies.
                </p>
                <p>
                  Secondly, we also monitor the community background and sex of
                  our job applicants and employees in order to comply with our
                  duties under the Fair Employment and Treatment (NI) Order
                  1998.
                </p>
                <p>
                  <strong>
                  Note: If you answer these questions about community background and sex you are obliged to do so truthfully, as it is a criminal offence under the Fair Employment (Monitoring) Regulations (NI) 1999 to knowingly give false answers to these questions.
                    
                  </strong>
                  <span className="info">
                  <button 
                      className="btn btn-secondary btn-circle"
                      onClick={e => {
                      handlers.togglePopup(e);
                    }}><i>i</i></button> 
                 

                  </span>
                   

                  {this.props.state.showPopup ?  
                  <InfoPopup
                    handlers={this.props.handlers}
                    state={this.props.state}  
                    text='You are not obliged to answer the questions on this form and
                    you will not suffer any penalty if you choose not to do so.'  
                    
                  />  
                   : null  
                   }  
                </p>
                <p>
                  Nevertheless, we encourage you to answer the questions below.
                  Your identity will be kept anonymous and your answers will be
                  treated with the strictest confidence. We assure you that your
                  answers will not be used by us to make any unlawful decisions
                  affecting you, whether in a recruitment exercise or during the
                  course of any employment with us. To protect your privacy, you
                  should not write your name on this questionnaire. The form
                  will carry a unique identification number and only our
                  Monitoring Officer will be able to match this to your name.
                </p>
              </div>
            </div>
            <CommunityBackground
              handlers={this.props.handlers}
              resource={this.props.state.resource}
              state={this.props.state}
              id={"communityBackground"}
            />
          </Fragment>
        ) : null}
      </Fragment>
    );

  }
}

export { Step1 };
