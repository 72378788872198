import React, {Component, Fragment} from 'react';

const renderTags = (tags, props) => {
    let handler
    return tags.map((tag, idx) => {
        if(tag.group === 'disabilities'){
            handler = props.handlers.handleMultiSelectDisabilities;
        } else {
            handler = props.handlers.handleMultiSelect;
        } 
        return(
            
            <div 
                key={idx} 
                className="badge badge-pill badge-secondary pills pointer"
                onClick={e => { handler(e, tag.key) }}
            >
                    {tag.label  }&nbsp;&nbsp;X
            </div>

            
           )

    })

}
class Tags extends Component {
    render() {
        return(
            <Fragment>
                {
                    this && this.props && this.props.tags ?
                    renderTags(this.props.tags, this.props) : null
                }
            </Fragment>
        )
    }

}

export { renderTags, Tags };