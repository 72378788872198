import React, { Component, Fragment } from "react";
import { Final } from "../final";


class Step4 extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    
  }
  render() {
    
    return (
      <Fragment>
        {this.props.state.currentStep === 4 && this.props.state.final === true ? (
          <Fragment>
                
            <Final state={this.state}/>
          </Fragment>
        ) : null}
      </Fragment>
    );

   
  }
}

export { Step4 };
