const singleSelectCommunityBackgroundState = {
    protestant: {
        label: "Protestant",
        id: "protestant",
        selected: false
    },
    catholic: {
        label: "Catholic",
        id: "catholic",
        selected: false
    },
    neither: {
        label: "Neither",
        id: "neither",
        selected: false
    }
  }

  const multiselectDisabilitiesState = {
    physicalImpairment: {
      category: 'multiselectDisabilities',
      key: "physicalImpairment",
      label: "Physical impairment",
      selected: false
    },
    sensoryImpairment: {
      key: "sensoryImpairment",
      label: "Sensory impairment",
      selected: false
    },
    mentalHealthCondition: {
      key: "mentalHealthCondition",
      label: "Mental health condition",
      selected: false
    },
    learningDisabilityOrDifficulty: {
      key: "learningDisabilityOrDifficulty",
      label: "Learning disability or difficulty, Cognitive impairment",
      selected: false
    },
    longStandingOrProgressiveIllness: {
      key: "longStandingOrProgressiveIllness",
      label: "Long-standing or progressive illness or health condition",
      selected: false
    },
    other: {
      key: "other",
      label: "Other",
      selected: false
    }
  }

const singleSelectSexState = {
    male: {
        label: "Male",
        id: "male",
        selected: false
    },
    female: {
        label: "Female",
        id: "female",
        selected: false
    }
}

const multiselectState = {
    child: {
      key: "child",
      label: "A child or children",
      selected: false
    },
    disabled: {
      key: "disabled",
      label: "A disabled person or persons",
      selected: false
    },
    elderly: {
      key: "elderly",
      label: "An elderly person or persons",
      selected: false
    },
    other: {
      key: "other",
      label: "Other",
      selected: false
    }
  }

  const singleSelectEthnicityState =  {
    white: {
        label: "White",
        id: "white",
        selected: false
    },
    irishTraveller: {
        label: "Irish Traveller",
        id: "irishTraveller",
        selected: false
    },
    chinese: {
        label: "Chinese",
        id: "chinese",
        selected: false
    },
    indian: {
        label: "Indian",
        id: "indian",
        selected: false
    },
    pakistani: {
        label: "Pakistani",
        id: "pakistani",
        selected: false
    },
    bangladeshi : {
        label: "Bangladeshi",
        id: "bangladeshi",
        selected: false
    },
    blackCaribbean: {
        label: "Black Caribbean",
        id: "blackCaribbean",
        selected: false
    },
    blackAfrican : {
        label: "Black African",
        id: "blackAfrican",
        selected: false
    },
    blackOther : {
        label: "Black Other",
        id: "blackOther",
        selected: false
    },
    mixedEthnicGroup: {
        label: "Mixed ethnic group",
        id: "mixedEthnicGroup",
        selected: false
    },
    anyOtherEthnicGroup: {
        label: "Any other ethnic group",
        id: "anyOtherEthnicGroup",
        selected: false

    }

}

const mockResponse = { data: {
	"communityBackground": ["Protestant", "Roman Catholic", "Neither"],
	"sex": ["Male", "Female"],
	"age": ["DD/MM/YYYY"],
	"racialGroup": {
		"countryOfBirth": ["Ireland", "England", "Scotland", "Wales", "France", "China", "India", "Nigeria", "Poland", "Turkey"],
		"nationality": ["Irish", "English", "Scottish", "Welsh", "French", "Chinese", "Indian", "Nigerian", "Polish", "Turkish"],
		"ethnicity": ["White", "Irish Traveller", "Pakistani", "Black Caribbean", "Black Other", "Chinese", "Indian", "Bangladeshi", "Black African", "Other", "Mixed"]
	},
	"disability": {
		"disabled": ["Yes", "No"],
		"disabilities": ["Physical impairment", "Sensory impairment", "Mental health condition", "Learning disability or difficulty, Cognitive impairment", "Long-standing or progressive illness or health condition", "Other"]
	},
	"partnershipStatus": ["Yes", "No"],
	"responsibilities": {
		"hasDependents": ["Yes", "No"],
		"dependents": ["A child or children", "A disabled person or persons", "An elderly person or persons", "Other"]

	}
}
}


  export { singleSelectCommunityBackgroundState, 
           multiselectDisabilitiesState,
           singleSelectSexState,
           multiselectState,
           singleSelectEthnicityState,
           mockResponse

            }